


























import Vue from 'vue';
import cookie from 'cookie';
import * as b2c from './b2c';
import * as jwt from './jwt';

function addErrorHandling() {
  function handleError() {
    // TODO Show "unknown error" message to user
  }

  window.onerror = (msg, url, line, col, error) => {
    handleError();
    console.error([msg, url, line, col, error]);
  };

  Vue.config.errorHandler = (err) => {
    handleError();
    console.error(err);
  };

  window.addEventListener('unhandledrejection', (event) => {
    handleError();
    console.error(event);
  });
}

export default Vue.extend({
  data() {
    return {
      loggedIn: false,
      companies: [] as { id: string; name: string; url: string }[]
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loggedIn = false;
      addErrorHandling();
      const cookies = cookie.parse(document.cookie);
      const accessToken = cookies.accessToken;
      let unexpiredAccessToken = false;

      if (accessToken) {
        const expires = new Date(jwt.getPayload(accessToken).exp * 1000);
        unexpiredAccessToken = expires > new Date();
      }

      if (unexpiredAccessToken) {
        fetch(b2c.getApiUrl() + 'companies' + (b2c.isMfa() ? '?mfa=true' : ''), {
          headers: { Authorization: 'Bearer ' + accessToken }
        }).then((response) => response.json()).then((json) => {
          this.loggedIn = true;
          this.companies = json as { id: string; name: string; url: string }[];
          this.companies.sort((a, b) => a.name.localeCompare(b.name));

          // Redirect to app
          if (this.companies?.length === 1) {
            window.location.href = this.getCompanyRedirectLink(this.companies[0]);
          }
        }).catch((reason) => {
          console.error(reason);
          document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          this.login();
        });
      } else {
        this.login();
      };
    },
    login() {
      // Redirect to login
      window.location.href = b2c.getB2CUri('B2C_1_sign-up_sign-in_2', b2c.getAppUrl() + 'b2c-redirect', this.$route.fullPath);
    },
    logout() {
      window.sessionStorage.removeItem('accessToken');
      window.sessionStorage.removeItem('accessTokenExpires');
      this.init();
    },
    getCompanyRedirectLink(company: { id: string; name: string; url: string }): string {
      let redirectPath = this.$route.fullPath?.replace(this.$route.matched[0].regex, (_, m1) => {
        return m1 || '';
      }) || '';

      if (redirectPath && !redirectPath.startsWith('/')) {
        redirectPath = '/' + redirectPath;
      }

      // Add fragment part while we are using fragment URLs
      if (redirectPath) {
        redirectPath = '/#' + redirectPath;
      }

      return company.url + redirectPath;
    }
  }
});
