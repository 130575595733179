import Login from '@/Login.vue';
import Redirect from '@/Redirect.vue';
import cookie from 'cookie';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import * as b2c from '../b2c';
import * as jwt from '../jwt';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/b2c-redirect',
    beforeEnter: (to, _, next) => {
      const fragment = to.hash;
      const idTokenMatch = fragment.match(/(?:^|&)id_token=(.*?)(?:&|$)/);
      const stateMatch = fragment.match(/(?:^|&)state=(.*?)(?:&|$)/);
      const redirectPath = (stateMatch && stateMatch[1]) || '/';

      if (idTokenMatch) {
        const token = idTokenMatch[1];
        const jsonPayload = jwt.getPayload(idTokenMatch[1]);
        const cookieOptions: cookie.CookieSerializeOptions = { path: '/', expires: new Date(jsonPayload.exp * 1000) };

        if (process.env.NODE_ENV === 'production') {
          cookieOptions.domain = 'sdf.cloud';
        }

        const accessTokenCookie = cookie.serialize('accessToken', token, cookieOptions);
        document.cookie = accessTokenCookie;
      } else {
        const passwordResetMatch = fragment.match(/(?:^|&)error_description=AADB2C90118/);

        if (passwordResetMatch) {
          window.location.href = b2c.getB2CUri('B2C_1_password_reset', b2c.getApiUrl() + 'b2c-redirect/redirectIdTokenResetPassword', b2c.getAppHost() + '/b2c-redirect?state=' + redirectPath, 'query');
          return;
        }
      }

      next(redirectPath);
    }
  },
  {
    path: '/',
    component: Login
  },
  {
    path: '/app',
    component: Redirect
  },
  {
    path: '/app/*',
    component: Redirect
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
