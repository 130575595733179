export function getAppHost() {
  if (process.env.NODE_ENV === 'production') {
    return 'login';
  } else {
    return 'localhost:8080';
  }
}

export function getAppUrl() {
  if (process.env.NODE_ENV === 'production') {
    return 'https://' + getAppHost() + '.sdf.cloud/';
  } else {
    return 'http://' + getAppHost() + '/';
  }
}

export function isMfa() {
  let mfa = false;

  if (window.URLSearchParams) {
    const urlParams = new URLSearchParams(window.location.search);
    mfa = urlParams.has('mfa');
  }

  return mfa;
}

export function getApiUrl() {
  if (process.env.NODE_ENV === 'production') {
    return 'https://user-api.sdf.cloud/';
  } else {
    return 'http://localhost:59012/';
  }
}

export function getB2CUri(policy: string, redirectUri: string, state: string, mode = 'fragment') {
  return (
    'https://sdfcloudb2c.b2clogin.com/sdfcloudb2c.onmicrosoft.com/oauth2/v2.0/authorize' +
    '?p=' +
    policy +
    (isMfa() ? '_mfa' : '') +
    '&client_id=637511e6-0d36-4152-84bb-b99f665b7073' +
    '&nonce=defaultNonce' +
    '&redirect_uri=' +
    encodeURIComponent(redirectUri) +
    '&scope=openid' +
    '&response_mode=' +
    encodeURIComponent(mode) +
    '&response_type=id_token' +
    '&prompt=login' +
    '&state=' +
    encodeURIComponent(state)
  );
}
